export interface Activity extends ActivityData {
	id: string
}

export interface ActivityData {
	endDate: Date
	enrolling: boolean
	name: string
	startDate: Date
	type: ActivityType
	participantType: ParticipantType
	serviceType: ServiceType
	activityCode: string,
  rollingEnrollment: boolean
}

export enum ActivityType {
	PLACEMENT_ACTIVITY = 'Placement Activity',
	INCUMBENT_WORKER_TRAINING = 'Incumbent Worker Training',
	UNEMPLOYED_TRAINING = 'Unemployed Training',
}

export interface ActivityReporter extends ActivityReporterData {
	id: string
}

export interface ActivityReporterData {
	email: string
	name?: string
}

export enum ParticipantType {
	NON_ENROLLED = "NON_ENROLLED",
	BUSINESS_OWNERS_ENTREPRENEURS = "BUSINESS_OWNERS_ENTREPRENEURS",
	INCUMBENT_WORKERS = "INCUMBENT_WORKERS",
	JOBSEEKERS_NEW_HIRES = "JOBSEEKERS_NEW_HIRES",
	OTHER_ENROLLED = "OTHER_ENROLLED"
}

export enum ServiceType {
	CAREER_EXPLORATION_ESSENTIAL_SKILLS = "CAREER_EXPLORATION_ESSENTIAL_SKILLS",
	WORKFORCE_TRAINING = "WORKFORCE_TRAINING",
	INTERNSHIPS_WORK_BASED_LEARNING = "INTERNSHIPS_WORK_BASED_LEARNING",
	GENERAL_EDUCATION = "GENERAL_EDUCATION",
	JOB_SEARCH_PLACEMENT_ASSISTANCE = "JOB_SEARCH_PLACEMENT_ASSISTANCE",
	SECTOR_INVESTMENTS = "SECTOR_INVESTMENTS"
}

export interface ActivityStatus {
	enrollment: boolean
}

export const ServiceTypeMapper = new Map<ServiceType, string>([
	[ServiceType.CAREER_EXPLORATION_ESSENTIAL_SKILLS, 'Career Exploration Essential Skills'],
	[ServiceType.WORKFORCE_TRAINING, 'Workforce Training'],
	[ServiceType.INTERNSHIPS_WORK_BASED_LEARNING, 'Internship Work Based Learning'],
	[ServiceType.GENERAL_EDUCATION, 'General Education'],
	[ServiceType.JOB_SEARCH_PLACEMENT_ASSISTANCE, 'Job Search Placement Assistance'],
	[ServiceType.SECTOR_INVESTMENTS, 'Sector Investments']
])

export const ParticipantTypeMapper = new Map<ParticipantType, string>([
	[ParticipantType.NON_ENROLLED, 'Not Enrolled'],
	[ParticipantType.BUSINESS_OWNERS_ENTREPRENEURS, 'Business Owners Entrepreneurs'],
	[ParticipantType.INCUMBENT_WORKERS, 'Incumbent Workers'],
	[ParticipantType.JOBSEEKERS_NEW_HIRES, 'Job Seekers / New Hires'],
	[ParticipantType.OTHER_ENROLLED, 'Other Enrolled'],
])